import "./css/contact.css";
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import RequestForm from "./request-form";
import giantHead from "../images/giant-head.jpg";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import BackgroundImage from "gatsby-background-image";

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "giant-head.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background: file(relativePath: { eq: "contact-background.png" }) {
          childImageSharp {
            fluid(maxWidth: 10000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <BackgroundImage
        Tag="section"
        className={"contact-component"}
        fluid={data.background.childImageSharp.fluid}
      >
        <Row>
          <Col md="7" className="contact-col">
            <h2 className="contact-header">CONTACT US</h2>
            <h1 className="contact-header">SAY HELLO.</h1>
            <Row>
              <Col md="6">
                <Img
                  fluid={data.image.childImageSharp.fluid}
                  className="img-fluid"
                />
              </Col>
              <Col md="6">
                <p className="mt-4">
                  PropMaker Studios
                  <br />
                  11304 Chandler Blvd #595,
                  <br />
                  North Hollywood, CA 91601
                </p>
                <p>
                  Phone: <a href="tel:1-818-738-7391">(818)738-7391</a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto: info@propmaker.com">info@propmaker.com</a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="5">
            <RequestForm />
          </Col>
        </Row>
      </BackgroundImage>
    )}
  />
);

export default Contact;
